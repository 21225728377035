import { memo, useEffect, useMemo, useState } from "react"

import type { Props } from "@app/pages/contact"
import type { PageProps } from "@root/types/global"
import { AspectRatio, Box, Container, Flex, Heading, Image, useTheme, Text } from "@chakra-ui/react"
import { useShopify } from "@app/hooks/useShopify"
import { ProductRecommended } from "@components/Product/ProductRecommended"
import { usePageProduct } from "@app/hooks/usePageProduct"
import { useCore } from "@app/hooks/useCore"
import { useWaitForGlobal } from "@app/hooks/useWaitForGlobal"
import { useConfigContext } from "@app/providers/config"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import { CustomLink } from "@components/Link"
// import { ProductReviews } from "@components/Product/ProductReviews"
// import ReviewsWriteForm from "@components/Reviews/ReviewsWriteForm"

const ReviewsWrite: React.FC<PageProps<Props>> = () => {
  const {
    helpers: { isBrowser, decodeShopifyId },
  } = useCore()

  const productHandle = useMemo(() => (isBrowser ? location?.pathname?.split("/review/write/")?.[1] : null), [isBrowser])
  const { getProducts } = useShopify()
  const [product, setProduct] = useState<any>(null)

  const productId = useMemo(() => decodeShopifyId(product?.id, "Product"), [product?.id, decodeShopifyId])

  const { brandCollection, brandCollectionUrl } = usePageProduct({
    sanityProduct: product,
  })
  const { waitForGlobal } = useWaitForGlobal()

  const [stampedGlobalReady, setStampedGlobalReady] = useState(false)
  const {
    store,
    settings: { routes },
  } = useConfigContext()

  const fetchItems = useMemo(
    () => async () => {
      if (productHandle) {
        setProduct(
          (
            await getProducts({
              firstVariants: 1,
              firstImages: 1,
              handles: [productHandle],
            })
          )?.[0]
        )
      }
    },
    [getProducts, productHandle]
  )

  // intentionally only run once at first render
  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function addEventListenerStamped(el, eventName, handler) {
    if (el.addEventListener) {
      el.addEventListener(eventName, handler)
    } else {
      el.attachEvent("on" + eventName, function () {
        handler.call(el)
      })
    }
  }

  useEffect(() => {
    if (isBrowser) {
      waitForGlobal("StampedFn", () => {
        setStampedGlobalReady(true)
      })
    }
  }, [isBrowser, waitForGlobal])

  useEffect(() => {
    // See https://support.stamped.io/article/832-javascript-events-references
    if (stampedGlobalReady && product) {
      window?.StampedFn.init({ apiKey: store?.stampedApiKey, storeUrl: store?.shopifyShopDomain })

      addEventListenerStamped(window?.document, "stamped:reviews:loaded", () => {
        window?.StampedFn?.toggleForm("review")
      })
    }
  }, [stampedGlobalReady, product, store])

  return (
    <Box as="section" pt={["80px", "107px"]} pb={[10, 18]} bg="brand.lightSand">
      <Container variant="example">
        <Heading as="h1" size={["3xl", "h2"]} mb={[8, 6]} textAlign="left">
          Write your review
        </Heading>
        {product && (
          <>
            <Flex flexDirection={["column", "row"]}>
              <AspectRatio ratio={1} w={["100%", "323px"]} mb={[6, 0]}>
                <Image src={product?.images?.[0]?.src} alt={product?.images?.[0]?.alt} />
              </AspectRatio>

              <Flex flexDir="column" justifyContent="center" ml={[0, 12]}>
                {brandCollection?.handle && (
                  <CustomLink to={brandCollectionUrl}>
                    <Flex role="group" alignItems="center" cursor="pointer">
                      <Text variant="md" fontWeight={700} lineHeight={1.5}>
                        {brandCollection?.title}
                      </Text>
                      <ArrowForwardIcon ml={1} w={4} _groupHover={{ transform: "translateX(8px)" }} transition="100ms ease-out" />
                    </Flex>
                  </CustomLink>
                )}
                <Heading size={["xl", "h4"]} fontSize={["24px", "30px"]} mt={1} mb={[4, 6]} textAlign="left">
                  {product?.title}
                </Heading>
                {/* <CustomLink as={CustomLink} to={urlResolver(product, routes.PRODUCT)?.url}>
                  <Button variant="outline" w="50%" size="lg">
                    Shop Now
                  </Button>
                </CustomLink> */}
              </Flex>
            </Flex>
            {/* See https://support.stamped.io/article/1116-main-reviews-widget-customizations */}
            <StyledStampedWidget>
              <div
                id="stamped-main-widget"
                className="stamped-main-widget"
                data-product-type={product?.productType}
                data-widget-style="standard"
                data-product-id={productId}
                data-name={isBrowser ? window?.encodeURIComponent(product?.title) : ""}
                data-url={`${store?.shopifyShopDomain}${routes?.PRODUCT}/${product?.handle}`}
                data-image-url={product?.images?.[0]?.src}
                data-description={isBrowser ? window?.encodeURIComponent(product?.description) : ""}
                data-product-sku={product?.handle}
                data-animation={false}
              ></div>
            </StyledStampedWidget>
            {/* <ReviewsWriteForm product={product} /> */}
            {/* <ProductReviews product={product} title="Other Honest Thoughts & Opinions" showWriteReview={false} productId={productId} /> */}
            <ProductRecommended id={product.id} title="You May Also Love" bgColor="transparent" />
          </>
        )}
      </Container>
    </Box>
  )
}

const StyledStampedWidget: React.FC = ({ children }) => {
  const { components } = useTheme()

  const stampedWidgetStyles = {
    "#stamped-main-widget": {
      m: 0,
    },
    "*": {
      fontFamily: "body",
      color: "brand.avocado",
    },
    ".fa-star-checked:before, .stamped-fa-star-checked:before, .fa-star:before, .stamped-fa-star:before": {
      fontSize: 0,
      content: `url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.34625 0.811203C8.08131 0.3546 7.42192 0.354598 7.15697 0.811203L5.29106 4.02685C5.16018 4.25241 4.93396 4.40634 4.6761 4.44528L1.17926 4.97344C0.59194 5.06214 0.385924 5.80369 0.843322 6.18264L3.44832 8.34085C3.68946 8.54063 3.80284 8.85576 3.74426 9.16337L3.06284 12.7419C2.95511 13.3076 3.54897 13.7471 4.05851 13.4788L7.35123 11.7451C7.60184 11.6131 7.90138 11.6131 8.152 11.7451L11.4447 13.4788C11.9543 13.7471 12.5481 13.3076 12.4404 12.7419L11.759 9.16337C11.7004 8.85576 11.8138 8.54063 12.0549 8.34085L14.6599 6.18264C15.1173 5.80369 14.9113 5.06214 14.324 4.97344L10.8271 4.44528C10.5693 4.40634 10.343 4.25241 10.2122 4.02685L8.34625 0.811203Z' fill='%23FC9173'/%3E%3C/svg%3E%0A") !important`,
      mx: "1px",
    },
    ".fa-star-o:before, .stamped-fa-star-o:before": {
      content: `url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.21429 1.58367C6.67795 0.784609 7.83187 0.784612 8.29554 1.58367L9.71546 4.03073C9.84635 4.25629 10.0726 4.41021 10.3304 4.44916L12.6883 4.80529C13.7161 4.96053 14.0766 6.25823 13.2762 6.92139L11.5582 8.34473C11.3171 8.54451 11.2037 8.85963 11.2623 9.16725L11.7435 11.6945C11.932 12.6844 10.8928 13.4536 10.0011 12.9841L7.6553 11.749C7.40468 11.617 7.10514 11.617 6.85453 11.749L4.50875 12.9841C3.61706 13.4536 2.57781 12.6844 2.76632 11.6945L3.24756 9.16725C3.30614 8.85963 3.19276 8.54451 2.95162 8.34473L1.23363 6.92139C0.43318 6.25823 0.793708 4.96052 1.82152 4.80529L4.1794 4.44916C4.43725 4.41021 4.66348 4.25629 4.79436 4.03073L6.21429 1.58367ZM7.40357 2.10124C7.33733 1.98709 7.17249 1.98709 7.10625 2.10124L5.68632 4.5483C5.39838 5.04453 4.90069 5.38316 4.33341 5.46884L1.97553 5.82497C1.8287 5.84715 1.77719 6.03253 1.89154 6.12727L3.60954 7.55061C4.14004 7.99012 4.38948 8.6834 4.26061 9.36015L3.77937 11.8874C3.75244 12.0288 3.9009 12.1387 4.02829 12.0716L6.37406 10.8365C6.92542 10.5462 7.58441 10.5462 8.13576 10.8365L10.4815 12.0716C10.6089 12.1387 10.7574 12.0288 10.7305 11.8874L10.2492 9.36015C10.1203 8.6834 10.3698 7.99013 10.9003 7.55061L12.6183 6.12727C12.7326 6.03253 12.6811 5.84715 12.5343 5.82497L10.1764 5.46884C9.60913 5.38316 9.11144 5.04453 8.8235 4.5483L7.40357 2.10124Z' fill='%23FC9173'/%3E%3C/svg%3E%0A")`,
      mx: "1px",
    },
    ".stamped-summary": {
      border: "unset",
    },
    ".stamped-review>.stamped-review-content, .stamped-header-right": {
      border: "unset",
    },
    ".stamped-summary-text-1, .stamped-summary-recommend-percent": {
      bg: "unset !important",
      color: "brand.avocado",
      fontFamily: "heading",
      fontSize: "47px !important",
      p: "0 !important",
      lineHeight: "1 !important",
    },
    ".stamped-summary-recommend": {
      display: "flex",
      flexDir: "column",
      alignItems: "flex-start",
    },
    ".stamped-summary-recommend-label, .stamped-summary-text": {
      fontSize: "14px",
    },
    ".stamped-summary-recommend-label": {
      mt: 2,
    },
    ".stamped-header-left": {
      w: ["full", "full", "30%"],
      textAlign: "unset",
    },
    ".stamped-header-right": {
      minH: "unset",
      w: ["full", "full", "67%"],
    },
    ".fa-star, .fa-star-o, .fa-star-half-o, .stamped-fa-star, .stamped-fa-star-o, .stamped-fa-star-half-o": {
      width: "24px",
      height: "24px",
    },
    ".stamped-review-content, .stamped-review-reply": {
      bgColor: "brand.offWhite",
      pl: "10% !important",
      pb: "14 !important",
      pt: ["7 !important", "14 !important"],
      pr: ["10 !important", "14 !important"],
      ".fa-star, .fa-star-o, .fa-star-half-o, .stamped-fa-star, .stamped-fa-star-o, .stamped-fa-star-half-o": {
        width: "15px",
        height: "15px",
      },
    },
    ".stamped-review-reply": {
      border: "unset !important",
      bgColor: "brand.offWhite !important",
      w: "full",
      pt: "0 !important",
      mt: "0 !important",
    },
    "#stamped-main-widget  div.stamped-review-product, .stamped-review-recommend[data-is-recommend=true]": {
      display: "none !important",
    },
    ".stamped-review": {
      bgColor: "brand.lightSand",
      borderColor: "brand.avocado",
      display: "flex",
      alignItems: "center",
      mb: "40px",
      "@media(max-width: 600px)": {
        flexDirection: "column",
      },
    },
    "#stamped-questions-tab .stamped-review": {
      bgColor: "brand.offWhite",
      flexWrap: "wrap",
    },
    "#stamped-questions-tab .stamped-review-header": {
      display: "flex",
      alignItems: "center",
    },
    ".stamped-review-header-title, .stamped-review-content-body *": {
      fontFamily: "heading",
      fontSize: ["4xl", "5xl"],
      lineHeight: ["41.6px", "46.8px"],
      mt: [4, 2],
      mb: [2, 4],
    },
    ".stamped-review .author": {
      fontFamily: "heading",
      fontSize: "20px",
    },
    ".stamped-review-content-body": {
      fontSize: "md",
    },
    ".stamped-review-avatar": {
      textShadow: "unset",
      mt: [0, "-10px"],
    },
    ".stamped-review-avatar-content": {
      fontFamily: "heading",
      color: "brand.avocado",
      fontSize: "calc(1.2rem)",
    },
    ".stamped-review-header .review-location": {
      fontSize: "15px",
    },
    ".created": {
      position: "absolute",
      top: ["unset", 6],
      bottom: [6, "unset"],
      right: ["unset", 7],
      left: [7, "unset"],
      fontSize: "xs",
      color: "brand.avocado !important",
    },
    ".stamped-review-footer .stamped-review-vote": {
      position: "absolute",
      bottom: 6,
      right: 7,
    },
    ".stamped-rating-holder": {
      fontSize: "xs",
    },
    ".stamped-review-header": {
      width: ["full", "24%"],
      minW: "30%",
      display: ["flex", "block"],
      alignItems: "center",
      flexWrap: "wrap",
      flexDirection: "row",
    },
    ".stamped-summary-keywords-list": {
      maxW: "full",
      maxH: "unset",
      overflow: "unset",
    },
    ".stamped-container[data-widget-show-upload=true] .stamped-file-uploader": {
      display: "none !important",
    },
    "ul.stamped-summary-keywords-list li, .stamped-summary-actions-newquestion,  .stamped-summary-actions-newreview, .stamped-summary-actions-clear, #stamped-button-submit, .stamped-button-primary":
      {
        boxShadow: "unset !important",
        lineHeight: "none",
        borderRadius: "base",
        fontWeight: "normal",
        border: "1.5px",
        bgColor: "brand.offWhite",
        borderColor: "brand.avocado",
        color: "brand.avocado",
        py: 2,
        minW: [25, 49],
        maxW: "full",
        fontSize: ["md", "xl"],
        textAlign: "center",
        _active: {
          bgColor: ["brand.offWhite", "brand.avocado"],
          borderColor: ["brand.avocado", "transparent"],
          color: ["brand.avocado", "brand.offWhite"],
        },
        _hover: {
          bgColor: ["brand.offWhite", "brand.avocado"],
          borderColor: ["brand.avocado", "transparent"],
          color: ["brand.avocado", "brand.offWhite"],
        },
      },
    ".stamped-summary-actions-clear": {
      bgColor: ["brand.offWhite", "brand.avocado"],
      borderColor: ["brand.avocado", "transparent"],
      color: ["brand.avocado", "brand.offWhite"],
      _before: {
        display: "none",
      },
    },
    "ul.stamped-summary-keywords-list li.selected": {
      bgColor: "brand.avocado !important",
      borderColor: "brand.avocado !important",
      color: "brand.offWhite !important",
    },
    ".stamped-main-widget-loading .stamped-reviews>span:first-of-type": {
      bgColor: "brand.peachy",
      p: 6,
      borderRadius: "4px",
    },
    ".stamped-container ul.stamped-tabs": {
      height: "unset",
    },
    ".stamped-container ul.stamped-tabs li": {
      width: "33% !important",
      fontSize: "2xl",
      textAlign: "center",
      color: "brand.avocado !important",
      fontWeight: 400,
      pt: 2,
      pr: 4,
      pb: 5,
      pl: 2,
      lineHeight: 1,
      borderColor: "gray.200 !important",
      borderBottom: "2px",
      m: 0,
      _after: {
        display: "none",
      },
      "&.active": {
        borderColor: "brand.avocado !important",
      },
    },
    ".stamped-reviews-search-text input.stamped-reviews-search-input, .stamped-questions-search-text input.stamped-questions-search-input":
      {
        border: "1px !important",
        borderColor: "brand.apricot !important",
        bg: "brand.offWhite",
        fontSize: "md",
        borderRadius: "base",
        clear: "unset",
        _focus: {
          borderColor: "brand.avocado",
        },
        _disabled: {
          background: "brand.disabledLight",
          borderColor: "brand.disabledDark",
          color: "brand.disabledDark",
        },
        _placeholder: {
          color: "brand.avocado",
          lineHeight: "1 !important",
          py: "4 !important",
          pr: "4 !important",
        },
      },

    "input.stamped-form-input-text, input.stamped-form-input-email": {
      ...components.Input.baseStyle.field,
      bgColor: "brand.offWhite",
    },
    textarea: {
      ...components.Textarea.baseStyle,
      bgColor: "brand.offWhite",
    },
    ".stamped-form-review-recommend label": {
      display: "flex",
      alignItems: "center",
      input: {
        mr: 2,
      },
    },
    "#stamped-button-submit": {
      ...components.Button.variants.solid,
    },
    "input.stamped-form-input": {
      height: "unset",
    },
    label: {
      mb: 2,
      "&:last-of-type": {
        mb: 6,
      },
    },
    ".stamped-form-custom-option-scale label": {
      float: "unset",
      width: "20px",
      m: "0 !important",
    },
    ".stamped-form-custom-option-scale label:first-of-type": {
      mt: "-10px",
    },
    ".stamped-form-review-rating input": {
      bg: "inherit",
    },
    ".stamped-form-custom-option-scale": {
      display: "flex",
      border: "unset",
    },
    "#stamped-sort-select, #stamped-sort-select-3": {
      fontSize: "md",
      borderColor: "brand.avocado !important",
      border: "1px !important",
      mt: 0,
    },
    ".stamped-reviews-filter-label, .stamped-icon-comment, .stamped-questions span.stamped-review-header-byline": {
      display: "none !important",
    },
    ".stamped-questions-search-icon": {
      display: "none !important",
    },
  }

  return <Box sx={stampedWidgetStyles}>{children}</Box>
}

export default memo(ReviewsWrite)
